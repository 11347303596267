import React from 'react';

import PropTypes from 'prop-types';

import { navigate } from 'gatsby';

import BaseLayoutSetup from './BaseLayoutSetup';

import projectCard from '../components/projectCard';

import 'react-awesome-button/dist/styles.css';

const PortfolioLayout = ({
  // loading,
  // timeout,
  // isProjectVisible,
  projects,
  // children,
}) => {
  const cardTypes = [
    'normal',
    'water',
    'fire',
    // 'psychic',
    // 'dark',
    'grass',
    // 'ice',
    // 'fairy',
  ];

  return (
    <BaseLayoutSetup>
      <div className="home-button-container nes-container is-rounded is-dark is-centered with-title">
        <h4 className="title">Return Home</h4>
        <button className="nes-btn" type="button" onClick={() => navigate('/')}>
          <div className="home-button-container-content">
            <i className="fa fa-home" />
            <i className="fa fa-arrow-left button-icon" />
          </div>
        </button>
      </div>

      <div className="nes-container is-rounded is-dark with-title is-centered portfolio-container">
        <h3 className="title">Portfolio</h3>
        {projects.map((project, index) => {
          const card = project.frontmatter;
          return projectCard({
            cardImage: card.featuredImage.childImageSharp.fluid,
            cardTags: card.tags,
            cardType: cardTypes[index % 4],
            projectSlug: project.fields.slug,
            cardTitle: card.title,
          });
        })}
      </div>
    </BaseLayoutSetup>
  );
};
PortfolioLayout.propTypes = {
  // children: PropTypes.oneOfType([
  //   PropTypes.arrayOf(PropTypes.node),
  //   PropTypes.node,
  // ]).isRequired,
  // loading: PropTypes.string,
  // isProjectVisible: PropTypes.bool,
  // timeout: PropTypes.bool,
};

export default PortfolioLayout;
