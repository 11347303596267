import { graphql } from 'gatsby';

import PropTypes from 'prop-types';

import PortfolioLayout from '../layouts/PortfolioLayout';

const Portfolio = ({ data }) => {
  return PortfolioLayout({
    projects: data.projects.nodes,
  });
};

Portfolio.propTypes = {
  location: PropTypes.shape({
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.objectOf(PropTypes.bool),
  }),
};

export default Portfolio;

export const query = graphql`
  query {
    projects: allMarkdownRemark {
      nodes {
        frontmatter {
          title
          date
          tags
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 150) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        fields {
          slug
        }
        rawMarkdownBody
      }
    }
  }
`;
